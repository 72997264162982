/**
 * Recent documents dashboard component
 *
 */

/**
 * React
 */
import * as React from 'react';
import { Link } from '@fluentui/react/lib/Link';

import { ICommunity } from './../../../controllers/CommunityController';
 
/**
 * GovTeams dependencies
 */
import * as constants from '../../../common/Constants';
import { isProtected } from '../../../common/Utils';
import GovTeamslogo from '../../../screens/Dashboard/images/LogoGTO.png';
import LogoProtectedPlatform from '../../../screens/Dashboard/images/LogoProtectedPlatform-265.png';
import {saveDashboardInteractionEvent} from '../../../common/EventTracking';
import { WAFFLE_PROPLATFORMAPP } from '../../../common/Constants';

interface IQuickLaunchProps {
  community?: ICommunity;
  showTeams: boolean;
  showSharepoint: boolean;
}

const QuickLaunch = (props: IQuickLaunchProps) => {
  const { community, showTeams, showSharepoint } = props;

  /**
   * Log event in GA
   * @param name
   */
  const _trackEvent = (name: string) => {
    saveDashboardInteractionEvent( 'quick_launch', name);
  };

  return (
    <div className='dashboard-quickLaunch'>

      {(!community && isProtected()) && (
        <Link
          className='quickLaunch-item'
          href={constants.WAFFLE_PROPLATFORMAPP}
          target='_blank'
          onClick={() => _trackEvent('PROTECTED Platform')}
        >
          <img className='item-icon-govteamsapp' src={LogoProtectedPlatform} alt='PROTECTED Platform'/>
          <div className='item-text'>
            <h3>PROTECTED Platform</h3>
            <div style={{ fontWeight: 400 }}>
              Access CabNet+, PDMS and other WhoG Apps
            </div>
          </div>
        </Link>
      )}

      {(!community && !isProtected()) && (
        <Link
          className='quickLaunch-item'
          href={!community ? constants.WAFFLE_GOVTEAMSAPP : community.url}
          target='_blank'
          onClick={() => _trackEvent('GovTeams App')}
        >
          <img className='item-icon-govteamsapp' src={GovTeamslogo} alt='GovTEAMS Logo' />

          <div className='item-text-govteamsapp'>
            <h3>GovTEAMS App</h3>
            <div style={{ fontWeight: 400 }}>
              Stay connected with the latest news
            </div>
          </div>
        </Link>
      )}

      {(!community && isProtected()) && (
        <Link
          className='quickLaunch-item'
          href={
            !community ? constants.WAFFLE_OUTLOOK : community.url
          }
          target='_blank'
          onClick={() => _trackEvent('Outlook')}
        >
          <div className='item-icon item-icon-outlook' />
          <div className='item-text'>
            <h3>Outlook</h3>
            <div style={{ fontWeight: 400 }}>
              Send and receive emails up to PROTECTED
            </div>
          </div>
        </Link>
      )}
      {(!community || showTeams) && (
        <Link
          className='quickLaunch-item'
          href={!community ? constants.WAFFLE_TEAMS : community.url}
          target='_blank'
          onClick={() => _trackEvent('Teams')}
        >
          <div className='item-icon item-icon-teams' />
          <div className='item-text'>
            <h3>Teams</h3>
            <div style={{ fontWeight: 400 }}>
              Connect and work with your team
            </div>
          </div>
        </Link>
      )}

      {(!community || showSharepoint) && (
        <Link
          className='quickLaunch-item'
          href={
            !community ? constants.WAFFLE_SHAREPOINT : community.sharePointUrl
          }
          target='_blank'
          onClick={() => _trackEvent('Sharepoint')}
        >
          <div className='item-icon item-icon-sharepoint' />
          <div className='item-text'>
            <h3>SharePoint</h3>
            <div style={{ fontWeight: 400 }}>
              Broadcast news, files and information
            </div>
          </div>
        </Link>
      )}
    </div>
  );
}

export default QuickLaunch;
