/**
 * Panel accessed from help button in app header
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Fabric UI
 */
import { IconButton } from '@fluentui/react/lib/Button';
import { Link } from '@fluentui/react/lib/Link';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { saveDashboardInteractionEvent } from '../../common/EventTracking';
import { isProtected } from '../../common/Utils';

export class HelpPanel extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isVisible: false
    };
  }

  public render(): React.ReactElement<any> {
    return (
      <div className='govTeams-help'>
        <IconButton
          iconProps={{ iconName: 'Unknown' }}
          title='Access help and support'
          ariaLabel='App launcher'
          onClick={this.showPanel}
        />
        <Panel
          className='govTeams-panel'
          isOpen={this.state.isVisible}
          onDismiss={this.panelDismiss}
          isBlocking={true}
          isLightDismiss={true}
          customWidth='450px'
          type={PanelType.custom}
        >
          <div className='panel-waffle'>
            <div className='panel-content'>
              <h2>Help and support</h2>
              <ul className='commandBar-tools'>
                <li>
                  <Link
                    href={isProtected() ? 'https://protectedgovteams.sharepoint.com/sites/academy' : 'https://govteams.sharepoint.com/sites/academy'}
                    target='_blank'
                  >
                  GovTEAMS Academy
                  </Link>
                </li>
                <li>
                  <Link
                    href='https://www.govteams.gov.au/support/'
                    target='_blank'
                  >
                    Contact support
                  </Link>
                </li>
                <li>
                  <Link
                    href='https://www.govteams.gov.au/provide-feedback/'
                    target='_blank'
                  >
                    Give feedback
                  </Link>
                </li>
                <li>
                  <Link
                    href='https://www.govteams.gov.au/report-misuse/'
                    target='_blank'
                  >
                    Report misuse
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Panel>
      </div>
    );
  }

  private showPanel = (): void => {
    saveDashboardInteractionEvent('help_clicked', 'help');
    this.setState({
      isVisible: true
    });
  };

  private panelDismiss = (): void => {
    this.setState({
      isVisible: false
    });
  };
}

export default HelpPanel;
